import React from 'react'
import Modal from 'react-bootstrap/Modal';

function TravelQuote (props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Preview</h6>
                    <p>
                        Travel Cover
                    </p>
                </div>
                {
                    props.underwriter !== 2 ?
                        <div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Country</label>
                                    <p>{props.data?.country} </p>
                                </div>
                                <div>
                                    <label>Depature Date</label>
                                    <p>{props.data?.cover_start}</p>
                                </div>
                                <div>
                                    <label>Arrival Date</label>
                                    <p>{props.data?.cover_end}</p>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Round Trip</label>
                                    <p>{props.data?.is_multi_trip}</p>
                                </div>
                                <div>
                                    <label>No Of Adult</label>
                                    <p>{props.data?.no_people}</p>
                                </div>
                                <div>
                                    <label>No of Children</label>
                                    <p>{props.data?.no_children}</p>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div className='total-premium'>
                                    <label>Amount</label>
                                    <p>{props.data?.amount}</p>
                                </div>
                                <div>
                                    <label></label>
                                    <input hidden/>
                                </div>
                                <div>
                                    <label></label>
                                    <input hidden/>
                                </div>
                            </div>
                        </div> :
                        <div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Country Category</label>
                                    <p>{props.data?.result?.currency} </p>
                                </div>
                                <div>
                                    <label>Premium</label>
                                    <p>{props.data?.result?.premium}</p>
                                </div>
                                <div>
                                    <label>Sum Assured</label>
                                    <p>{props.data?.result?.sumAssured}</p>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div className='total-premium'>
                                    <label>Premium</label>
                                    <p>{props.data?.result?.premium}</p>
                                </div>
                                <div>
                                    <label></label>
                                    <input hidden/>
                                </div>
                                <div>
                                    <label></label>
                                    <input hidden/>
                                </div>
                            </div>
                        </div>

                }
                <div className={`next-box `}>
                    <div onClick={() => props.cont()}>Continue</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TravelQuote
