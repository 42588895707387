import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { usePaystackPayment } from 'react-paystack';
import Termsmodal from '../../Components/modal/Termsmodal';
import {useTravel} from "../../Hooks/travel";

function PreviewBuyTravel(props) {
    const initializePayment = usePaystackPayment(props.config);
    const { verifypolicy } = useTravel()
    const [modal, setModal] = useState(false)

    const onSuccess = () => {
        // const data = {
        //     "amount": props.config.amount.toString(),
        //     "aiico_reference": props.config.aiico_reference,
        //     "reference": props.fullConfig.reference
        // }
        // console.log(data)
        console.log('here checkcomplete', props.config.amount.toString(), props.config.aiico_reference, props.fullconfig.reference)
        // props.startLoader()
        verifypolicy({
            "amount": props.config.amount.toString(),
            "aiico_reference": props.config.reference,
            "reference": props.fullconfig.reference
        })
    };
    const onClose = () => {
        console.log('here')
        props.onHide();
    }

    const checkcomplete = () => {
        console.log('here checkcomplete')
        initializePayment(onSuccess, onClose)
        props.startLoader()
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Preview</h6>
                    <p>
                        Travel Cover
                    </p>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Country Category</label>
                        <p>{props.data?.result?.currency} </p>
                    </div>
                    <div>
                        <label>Premium</label>
                        <p>{props.data?.result?.premium}</p>
                    </div>
                    <div>
                        <label>Sum Assured</label>
                        <p>{props.data?.result?.sumAssured}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div className='total-premium'>
                        <label>Premium</label>
                        <p>{props.data?.result?.premium}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden/>
                    </div>
                    <div>
                        <label></label>
                        <input hidden/>
                    </div>
                </div>
                <div className={`next-box`}>
                    <div onClick={checkcomplete}>Continue</div>
                </div>
                <Termsmodal show={modal} onHide={() => setModal(false)}/>
            </Modal.Body>
        </Modal>
    )
}

export default PreviewBuyTravel
