import React, {useCallback, useEffect, useRef, useState} from "react";
import {ReactComponent as Arrow} from "../../Assets/icons/back-arrow.svg";
import {Link, useNavigate} from "react-router-dom";
import {useCustomer} from "../../Hooks/customer";
import Table from "react-bootstrap/Table";
import {useUnderwriter} from "../../Hooks/underwriter";
import Loader from "../../Components/Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import {ReactComponent as Nexticon} from "../../Assets/icons/nextarrw.svg";
import {ReactComponent as Previcon} from "../../Assets/icons/prevarrw.svg";
import ReactPaginate from "react-paginate";
import {Cookies} from "react-cookie";
import {useTravel} from "../../Hooks/travel";
import PreviewQuote from "./previewQuote";
import Emailsent from "../../Components/modal/Email-modal";
import ButttonLoader from "../../Components/Loader/ButttonLoader";
import {SearchNormal1} from "iconsax-react";
import TableLoader from "../../Components/Loader/TableLoader";
import PreviewBuyTravel from "./preview-buy-travel";

function Buytravel() {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [verifyIsLoading, setVerifyIsLoading] = useState(null)
    const [travelmodal, setTravelModal] = useState(false);
    const cookies = new Cookies();
    const [page, setPage] = useState("");
    const [emailmodal, setEmailModal] = useState(false);
    const {customer, data , isLoading: customerLoading} = useCustomer();
    const {
        getCountry,
        data: countries,
        isLoading,
        getBookingType,
        bookingType,
        quote,
        travelQuote,
        getTitle,
        getStates,
        getGender,
        getMaritalStatus,
        title,
        states,
        gender,
        maritalstatus,
        buyTravel,
        buyFamilyTravel,
        error,
        quoteError,
        getTravelType,
        subClasses,
        getCountryByTravelType,
        countryByTravelType,
        aiicoTravelQuote,
        buyAiicoTravel,
        buyAiicoCardTravel,
        config,
        fullConfig,
    } = useTravel()
    const {
        getunderwriter,
        underwriters,
    } = useUnderwriter();
    const [customerinfo, setCustomerInfo] = useState({})
    const [travelData, setTravelData] = useState({
        "underwriter_id": 0,
        "enrollee_id": 0,
        "dob": "",
        "email": "",
        "telephone": "",
        "cover_begins": "",
        "cover_ends": "",
        "country_id": "",
        "purpose_of_travel": "",
        "booking_type_id": 0,
        "is_round_trip": false,
        "no_of_people": 0,
        "no_of_children": 0
    });
    const [buydata, setData] = useState({
        "firstname": "",
        "enrollee_id": 0,
        "lastname": "",
        "middlename": "",
        "email": "",
        "phone": "",
        "quote_id": "",
        "gender_id": "",
        "title_id": "",
        "dob": "",
        "state_id": "",
        "address": "",
        "zip_code": "",
        "nationality": "",
        "passport_no": "",
        "occupation": "",
        "marital_status_id": "",
        "pre_exisiting_medical_condition": false,
        // "medical_condition": "",
        "next_of_kin_name": "",
        "next_of_kin_address": "",
        "next_of_kin_relationship": "",
        "next_of_kin_phone": ""
    })
    const [aiicoQuoteTravelData, setAiicoQuoteTravelData] = useState({
        "destination_country_id": '',
        "subclass_sect_covtype_id": '',
        "dob": '',
        "start_date": '',
        "end_date": '',
    })
    // const [travelCategory, setTravelCategory] = useState('')
    let userid = JSON.parse(atob(cookies.get("xhrUserxx")))
    console.log(userid);
    let getinfo = cookies.get("xirhnfo");
    const [today, setToday] = useState("")
    const [step, setStep] = useState(0);

    const hideemail = () => {
        setEmailModal(false);
        navigate("/policy/travel/all");
    };

    const quotedata = (data) => {
       return Object.entries(data).every(([key, value]) => {
            const trimmedValue = typeof value === 'string' ? value.trim() : value;
            if (key === 'email' || key === 'telephone') {
                return true;
            }
            return trimmedValue !== "";
        });
    }
    const buyverification = (buydata) => {
        return Object.entries(buydata).every(([key, value]) => {
            const trimmedValue = typeof value === 'string' ? value.trim() : value;
            if (key === 'dob' || key === 'phone' || key === 'email' || key === 'firstname' || key === 'lastname' || key === 'pre_exisiting_medical_condition' || key === 'middlename') {
                return true;
            }
            return trimmedValue !== "";
        });
    }
    const next = () => {
        setModal(false);
        if (travelData.booking_type_id === 1) {
            setPage("completeform");
        } else {
            setPage("completeform");
            setStep(1);
        }
        setData({...buydata, quote_id: quote.travel_quote_id})
    }
    const noOfPeople = useRef(1)
    const getQUOTE = async () => {
        if (travelData.underwriter_id !== 2) {
            let data = {
                "underwriter_id": travelData.underwriter_id,
                "user_id": userid.userid,
                "enrollee_id": travelData.enrollee_id,
                "dob": travelData.dob,
                "email": travelData.email === "" ? customerinfo.email : travelData.email,
                "telephone": travelData.telephone === "" ? customerinfo.phone : travelData.telephone,
                "cover_begins": travelData.cover_begins,
                "cover_ends": travelData.cover_ends,
                "country_id": travelData.country_id,
                "purpose_of_travel": travelData.purpose_of_travel,
                "booking_type_id": travelData.booking_type_id,
                "is_round_trip": travelData.is_round_trip,
                "no_of_people": travelData.no_of_people,
                "no_of_children": travelData.no_of_children
            }
            if (travelData.booking_type_id)
                noOfPeople.current = travelData.no_of_people + travelData.no_of_children
            await travelQuote(data, setModal)
            setData({...buydata, email: travelData.email, phone: travelData.telephone, dob: travelData.dob})

        } else {
            await aiicoTravelQuote(aiicoQuoteTravelData, setModal)
        }

    };
    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };
    const errors = useRef({
        phone: '',
        passportNo: ''
    })
    const familyData = useRef([])
    const buypolicy = async () => {
        console.log('1 here i am')
        if(travelData.underwriter_id !== 2) {
            let data = {}
            if (buydata.firstname === ''){
                data = {
                    "firstname": customerinfo.firstname,
                    "enrollee_id": buydata.enrollee_id,
                    "lastname": customerinfo.lastname,
                    "middlename": buydata.middlename,
                    "email": travelData.email === "" ? customerinfo.email : travelData.email,
                    "phone": travelData.telephone === "" ? customerinfo.phone : travelData.telephone,
                    "quote_id": buydata.quote_id,
                    "gender_id": buydata.gender_id,
                    "title_id": buydata.title_id,
                    "dob": travelData.dob,
                    "state_id": buydata.state_id,
                    "address": buydata.address,
                    "zip_code": buydata.zip_code,
                    "nationality": buydata.nationality,
                    "passport_no": buydata.passport_no,
                    "occupation": buydata.occupation,
                    "marital_status_id": buydata.marital_status_id,
                    "pre_exisiting_medical_condition": buydata.pre_exisiting_medical_condition,
                    // "medical_condition": "",
                    "next_of_kin_name": buydata.next_of_kin_name,
                    "next_of_kin_address": buydata.next_of_kin_address,
                    "next_of_kin_relationship": buydata.next_of_kin_relationship,
                    "next_of_kin_phone": buydata.next_of_kin_phone
                }
            }else {
                data = {
                    "firstname": buydata.firstname,
                    "enrollee_id": buydata.enrollee_id,
                    "lastname": buydata.lastname,
                    "middlename": buydata.middlename,
                    "email": travelData.email === "" ? customerinfo.email : travelData.email,
                    "phone": travelData.telephone === "" ? customerinfo.phone : travelData.telephone,
                    "quote_id": buydata.quote_id,
                    "gender_id": buydata.gender_id,
                    "title_id": buydata.title_id,
                    "dob": travelData.dob,
                    "state_id": buydata.state_id,
                    "address": buydata.address,
                    "zip_code": buydata.zip_code,
                    "nationality": buydata.nationality,
                    "passport_no": buydata.passport_no,
                    "occupation": buydata.occupation,
                    "marital_status_id": buydata.marital_status_id,
                    "pre_exisiting_medical_condition": buydata.pre_exisiting_medical_condition,
                    // "medical_condition": "",
                    "next_of_kin_name": buydata.next_of_kin_name,
                    "next_of_kin_address": buydata.next_of_kin_address,
                    "next_of_kin_relationship": buydata.next_of_kin_relationship,
                    "next_of_kin_phone": buydata.next_of_kin_phone
                }
            }
            if (travelData.booking_type_id === 1) {
                await buyTravel(data, setEmailModal)

            } else {
                if (step < noOfPeople.current) {
                    console.log('here first')
                    console.log(familyData.current.length)
                    if (familyData.current.length < noOfPeople.current) {
                        console.log(buydata)
                        await familyData.current.push({ ...buydata });
                        setStep((prevStep) => prevStep + 1);
                        console.log('here i am')
                        console.log(familyData.current)

                    }
                    // setFamilyData((prevfamilydata) => [...prevfamilydata, buydata]);
                    console.log(familyData.current)
                } else {
                    console.log('igot here 2')
                    setData({...buydata, pre_exisiting_medical_condition: buydata.pre_exisiting_medical_condition === '' ? false : true})
                    await familyData.current.push({ ...buydata });
                    if (familyData.current.length ===  step) {

                    }
                    await buyFamilyTravel(familyData.current, setEmailModal, customerinfo.email)
                }
                for (const key in buydata) {
                    if (buydata.hasOwnProperty(key) && key !== 'enrollee_id' && key !== 'user_id' && key !== 'quote_id' && key !== 'state_id') {
                        switch (typeof buydata[key]) {
                            case 'string':
                                buydata[key] = '';
                                break;
                            case 'boolean':
                                buydata[key] = false;
                                break;
                            case 'number':
                                buydata[key] = 0;
                                break;
                            // You can add more cases for other types if needed
                            default:
                                // Do nothing for other types
                                break;
                        }
                    }
                }
            }
        } else {
            if(mode === 'spl' ){
                await buyAiicoTravel(travelDetails, customerinfo.email, setEmailModal)
            } else {
                await buyAiicoCardTravel(travelDetails, setTravelModal)
            }
        }
    }
    const [mode, setMode] = useState('')
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 20;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const dob = useRef("")
    const formatDob = (props) => {
        const propsYear = props.getFullYear();
        const propsMonth = String(props.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const propsDay = String(props.getDate()).padStart(2, '0');
        dob.current = `${propsYear}-${propsMonth}-${propsDay}`;
        console.log(dob.current);
        return`${propsYear}-${propsMonth}-${propsDay}`
    };
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };
    useEffect(() => {
        if (getinfo !== undefined) {
            setPage("startform");
        }
        const formattedDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
            const day = String(today.getDate()).padStart(2, '0');
            setToday(`${year}-${month}-${day}`)
            return `${year}-${month}-${day}`;
        }
        getTravelType()
        customer();
        getunderwriter();
        getCountry();
        getBookingType();
        getTitle();
        getStates();
        getGender();
        getMaritalStatus();
        formattedDate();
    }, [getTravelType, getinfo, customer, getunderwriter, getCountry, getBookingType, getTitle, getStates, getGender, getMaritalStatus,]);

    const handleEmailChange = (e) => {
        const newEmailValue = e.target.value === "" ? customerinfo.email : e.target.value;
        setTravelData({...travelData, [e.target.name]: newEmailValue});
    };
    const handleControlledInput = (e, name) => {
        if (name === "firstname"){
            const newValue = e.target.value === "" ? customerinfo.firstname : e.target.value;
            setData({...buydata, [e.target.name]: newValue});
        }else {
            const newValue = e.target.value === "" ? customerinfo.lastname : e.target.value;
            setData({...buydata, [e.target.name]: newValue});
        }
    };
    const validatePhoneNumber = (phoneNumber, name) => {
        const newPhoneValue = phoneNumber === "" ? customerinfo.phone : phoneNumber;
        setTravelData({...travelData, [name]: newPhoneValue});
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        const expectedLength = 11;
        if (digitsOnly.length === expectedLength) {
            if( name === 'sms') {
                setTravelDetails((prevData) => ({ ...prevData, [name]: newPhoneValue }));
            } else if (name === 'telephone') {
                setTravelData((prevData) => ({ ...prevData, [name]: newPhoneValue }));
            } else {
                console.log('hrhrhrhr')
                setData((prevData) => ({ ...prevData, [name]: phoneNumber }));
            }
            errors.current.phone = '';
        } else {
            errors.current.phone = 'Invalid Phone Number';
        }

        // setDummyState((prev) => !prev);
    };

    const validatePassportNo = (e) => {
        // Get the current value of passport_no
        let value = e.target.value
        // Ensure the first character is a letter and the rest are digits
        const regex = /^[A-Za-z][0-9]{8}$/i;  // Use the 'i' flag for case-insensitivity

        if (regex.test(value)) {
            setTravelDetails((prevData) => ({ ...travelDetails, [e.target.name]: value.toUpperCase() }));
            errors.current.passportNo = ""
        } else {
            // Invalid format, handle accordingly (show an error message, etc.)
            // For now, you can clear the input
            setTravelDetails((prevData) => ({ ...prevData, [e.target.name]: '' }));
            errors.current.passportNo = "Invalid Passport Number"

        }
        // setDummyState((prev) => !prev);
    }
    const [travelDetails, setTravelDetails] = useState({
        underwriter_id: '',
        enrollee_id: '',
        premium_id: '',
        start_date: '',
        end_date: '',
        next_of_kin_name: '',
        next_of_kin_address: '',
        next_of_kin_relationship: '',
        titleId: '',
        passportNumber: '',
        sms: '',
        preMedical: 'false',
        passportUrl: ''
    })
    // const [setDummyState] = useState(false);
    console.log(buyverification(travelDetails), isLoading, verifyIsLoading, 'fff')
    function getOrdinalNumber(number) {
        const suffixes = ["th", "st", "nd", "rd"];
        const remainder10 = number % 10;
        const remainder100 = number % 100;

        if (remainder10 >= 1 && remainder10 <= 3 && (remainder100 < 10 || remainder100 >= 20)) {
            return number + suffixes[remainder10];
        } else {
            return number + suffixes[0];
        }
    }
    const [filteredItemOffset, setFilteredItemOffset] = useState(0);
    const {searchCustomer, data: searchedData, isLoading: loader} = useCustomer()
    const [searchInput, setSearchInput] = useState('')
    const search = useCallback(async () => {
        try {
            await searchCustomer(searchInput);
        } catch (err) {
            console.error(err);
        }
    }, [searchCustomer, searchInput]);
    const filteredEndOffset = filteredItemOffset + itemsPerPage;
    const filteredPageCount = Math.ceil(searchedData.length / itemsPerPage);
    const handleFilteredPageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % searchedData.length;
        setFilteredItemOffset(newOffset);
    };
    const [filename, setFilename] = useState({
        passportName: "",
    });
    const click1 = useRef("");
    const file1 = () => {
        click1.current.click();
    };
    function encodeImageFileAsURL(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        setFilename({ ...filename, passportName: file.name });
        reader.onloadend = function () {
            setTravelDetails({ ...travelDetails, passportUrl: reader.result });
        };
        reader.readAsDataURL(file);
    }

    const normalPost = data.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + " " + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="buy-policy buy-policy-but"
                            onMouseOver={() => {
                                setCustomerInfo({
                                    ...customerinfo,
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    email: data.email,
                                    phone: data.phone,
                                    enrollee_id: data.id,
                                    address: data.address,
                                });
                                setTravelData({
                                    ...travelData,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                                setData({
                                    ...buydata,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                                setTravelDetails({...travelDetails, enrollee_id: data.id })
                            }}
                            onClick={() => setPage("startform")}
                        >
                            Buy
                        </Dropdown.Toggle>
                    </Dropdown>
                </td>
            </tr>
        );
    })
    const filteredPost = searchedData.slice(filteredItemOffset, filteredEndOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + " " + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="buy-policy buy-policy-but"
                            onMouseOver={() => {
                                setCustomerInfo({
                                    ...customerinfo,
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    email: data.email,
                                    phone: data.phone,
                                    enrollee_id: data.id,
                                    address: data.address,
                                });
                                setTravelData({
                                    ...travelData,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                                setData({
                                    ...buydata,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                            }}
                            onClick={() => setPage("startform")}
                        >
                            Buy
                        </Dropdown.Toggle>
                    </Dropdown>
                </td>
            </tr>
        );
    })

return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow/>
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Buy Travel Policy</h4>
                <div className="claims-table-container buy-vehicle-box">
                    <form className="health-form buy-form" autoComplete="off">
                        <div
                            className={`${getinfo !== undefined ? "d-none" : page !== "" ? "d-none" : ""
                            }`}
                        >
                            <div className="lists-cust">
                                <div className={`row justify-content-between align-items-center  py-3`}>
                                    <h5 className={`col-2`}>Customer</h5>
                                    <div className={`col-4 justify-content-end`}>
                                        <div className={`d-flex`}>
                                            <input placeholder='search customer'
                                                   onChange={(e) => setSearchInput(e.target.value)}
                                                   className={`focus-visible:outline-none searchinputCustomer rounded-start`}/>
                                            <button type='button' className='p-2 border-0 buy-policy rounded-end d-flex'
                                                    onClick={() => loader ? '' : search()}>
                                                {
                                                    loader ? <ButttonLoader/> : <SearchNormal1 size="24" color="white"/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Table className="">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th colSpan={2}>Address</th>
                                        <th>Phone Number</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loader || customerLoading ?
                                        <tr>
                                            <td className='border-0' colSpan={6} rowSpan={6}>
                                                <div className='d-flex justify-content-center py-5'>
                                                    <TableLoader/>
                                                </div>
                                            </td>
                                        </tr> :
                                        searchedData.length !== 0 ? filteredPost : normalPost}

                                    </tbody>
                                </Table>
                                { loader || customerLoading ? <div></div> : searchedData.length !== 0 ?
                                    <ReactPaginate
                                        breakLabel="..."
                                        previousLabel={<Previcon/>}
                                        nextLabel={<Nexticon />}
                                        pageCount={filteredPageCount}
                                        onPageChange={handleFilteredPageClick}
                                        containerClassName={"paginatecontainer"}
                                        previousLinkClassName={"prevbtn"}
                                        pageClassName={"pageli"}
                                        nextLinkClassName={"nextbtn"}
                                        disabledClassName={"paginationdisabled"}
                                        activeClassName={"paginationactive"}
                                        breakClassName={"breakli"}
                                        renderOnZeroPageCount={null}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                    />
                                    :
                                    <ReactPaginate
                                        breakLabel="..."
                                        previousLabel={<Previcon/>}
                                        nextLabel={<Nexticon />}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"paginatecontainer"}
                                        previousLinkClassName={"prevbtn"}
                                        pageClassName={"pageli"}
                                        nextLinkClassName={"nextbtn"}
                                        disabledClassName={"paginationdisabled"}
                                        activeClassName={"paginationactive"}
                                        breakClassName={"breakli"}
                                        renderOnZeroPageCount={null}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                    />
                                }
                            </div>
                        </div>
                        <div className={`${page === "startform" ? "" : "d-none"}`}>
                            <div className="input-group justify-content-start px-4 underwriter-group">
                                <div>
                                    <label>Choose provider</label>
                                    <select
                                        name="underwriter_id"
                                        defaultValue="Select Customer"
                                        onChange={(e) => {
                                            setTravelData({
                                                ...travelData,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            });
                                            setTravelDetails({
                                                ...travelDetails,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            })
                                        }}
                                    >
                                        <option defaultValue="" disabled selected>
                                            Select Provider
                                        </option>
                                        {underwriters.map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}
                                                        className={`${data.name !== "Allianz" && data.name !== "Aiico" ? "d-none" : ""}`}>
                                                    {data.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            {
                                travelData.underwriter_id !== 2 ?
                                    <div className={`${travelData.underwriter_id === 0 ? 'd-none' : ''}`}>
                                        <div className="input-group underwriter-group">
                                            <div>
                                                <label>Purpose Of Travel </label>
                                                <input type="text" name="purpose_of_travel" onChange={(e) => {
                                                    setTravelData({...travelData, [e.target.name]: e.target.value})
                                                }}/>
                                            </div>
                                            <div>
                                                <label>Booking Type</label>
                                                <select
                                                    name="booking_type_id"
                                                    onChange={(e) => {
                                                        if (JSON.parse(e.target.value) === 1) {
                                                            setTravelData({
                                                                ...travelData,
                                                                [e.target.name]: JSON.parse(e.target.value),
                                                                no_of_people: 1
                                                            });
                                                        } else {
                                                            setTravelData({
                                                                ...travelData,
                                                                [e.target.name]: JSON.parse(e.target.value),
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <option defaultValue="" disabled selected>
                                                        Select Booking Type
                                                    </option>
                                                    {bookingType.map((type, index) => {
                                                        return (
                                                            <option value={type.id} key={index}>{type.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div>
                                                <label>Round Trip</label>
                                                <select name="is_round_trip" onChange={(e) => {
                                                    setTravelData({
                                                        ...travelData,
                                                        [e.target.name]: JSON.parse(e.target.value)
                                                    })
                                                }}>
                                                    <option value='false'>No</option>
                                                    <option value='true'>Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-group underwriter-group">
                                            <div>
                                                <label>Email</label>
                                                <input type="email" name="email"
                                                       value={travelData.email === "" ? customerinfo.email : travelData.email}
                                                       onChange={(e) => handleEmailChange(e)}/>
                                            </div>
                                            <div>
                                                <label>Phone</label>
                                                <input type="tel" name="telephone"
                                                       value={travelData.telephone === "" ? customerinfo.phone : travelData.telephone}
                                                       onInput={(e) => {
                                                           validatePhoneNumber(e.target.value, e.target.name);
                                                       }}/>
                                                <p className="fs-6 fst-italic text-danger">{errors.current.phone}</p>
                                            </div>
                                            <div>
                                                <label>Date Of Birth</label>
                                                <input type="date" name="dob" max={today} onChange={(e) => {
                                                    setTravelData({...travelData, [e.target.name]: e.target.value});
                                                    formatDob(new Date(e.target.value))
                                                }}/>
                                                <p className={`${travelData.booking_type_id === 2 ? "text-danger fs-6" : "d-none"}`}>*
                                                    The dob of the oldest member</p>
                                            </div>
                                        </div>
                                        <div className="input-group underwriter-group">
                                            <div>
                                                <label>Departure Date</label>
                                                <input type="date" name="cover_begins" min={today} onChange={(e) => {
                                                    setTravelData({...travelData, [e.target.name]: e.target.value})
                                                }}/>
                                            </div>
                                            <div>
                                                <label>Arrival Date</label>
                                                <input type="date" name="cover_ends" min={travelData.cover_begins}
                                                       onChange={(e) => {
                                                           setTravelData({
                                                               ...travelData,
                                                               [e.target.name]: e.target.value
                                                           })
                                                       }} disabled={travelData.cover_begins === "" ? true : false}/>
                                            </div>
                                            <div>
                                                <label>Country</label>
                                                <select
                                                    name="country_id"
                                                    onChange={(e) =>
                                                        setTravelData({
                                                            ...travelData,
                                                            [e.target.name]: e.target.value,
                                                        })
                                                    }
                                                >
                                                    <option>Select Country</option>
                                                    {
                                                        countries.map((country, index) => {
                                                            return (
                                                                <option key={index}
                                                                        value={country.id}>{country.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-group underwriter-group justify-content-start px-lg-4">
                                            <div className={`${travelData.booking_type_id !== 2 ? "d-none" : ""}`}>
                                                <label>Number Of Adult</label>
                                                <input type="number" name="no_of_people" onChange={(e) => {
                                                    setTravelData({
                                                        ...travelData,
                                                        [e.target.name]: JSON.parse(e.target.value),
                                                    })
                                                }}/>
                                            </div>
                                            <div className={`${travelData.booking_type_id !== 2 ? "d-none" : ""}`}>
                                                <label>Number Of Children</label>
                                                <input type="number" name="no_of_children" onChange={(e) => {
                                                    setTravelData({
                                                        ...travelData,
                                                        [e.target.name]: JSON.parse(e.target.value),
                                                    })
                                                }}/>
                                                <p className={`${travelData.booking_type_id === 2 ? "text-danger fs-6" : "d-none"}`}>*
                                                    Any applicant with an age below 18 are considered has children</p>
                                            </div>
                                            {/* <div>
                                    <label>Payment mode</label>
                                    <select onChange={(e) => setMode(e.target.value)}>
                                        <option>Select State</option>
                                        <option value='pat'>Pay with Transfer</option>
                                        <option value='pac'>Pay with Card</option>
                                    </select>
                                </div> */}
                                        </div>
                                        <div>
                                            {/*<p className="text-danger"><em>{err}</em></p>*/}
                                        </div>
                                    </div> :
                                    <div>
                                        <div className="input-group underwriter-group">
                                            <div>
                                                <label>Travel Type </label>
                                                <select
                                                    name="booking_type_id"
                                                    onChange={(e) => {
                                                        console.log(e.target.value);
                                                        const [id, category] = e.target.value.split('|');
                                                        console.log(id, category);
                                                        setAiicoQuoteTravelData({...aiicoQuoteTravelData, subclass_sect_covtype_id: Number(id)});
                                                        // setTravelCategory(category)
                                                        getCountryByTravelType(category)
                                                    }} >
                                                    <option defaultValue="" disabled selected>
                                                        Select Travel Type
                                                    </option>
                                                    {subClasses.map((type, index) => {
                                                        return (
                                                            <option value={`${type.id}|${type.category}`} key={index} >{type.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div>
                                                <label>Country</label>
                                                <select
                                                    name="destination_country_id"
                                                    onChange={(e) =>
                                                        setAiicoQuoteTravelData({
                                                            ...aiicoQuoteTravelData,
                                                            [e.target.name]: Number(e.target.value),
                                                        })
                                                    }
                                                >
                                                    <option>Select Country</option>
                                                    {
                                                        countryByTravelType.map((country, index) => {
                                                            return (
                                                                <option key={index}
                                                                        value={country.id}>{country.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div>
                                                <label>Date Of Birth</label>
                                                <input type="date" name="dob" max={today} onChange={(e) => {
                                                    setAiicoQuoteTravelData({...aiicoQuoteTravelData, [e.target.name]: formatDob(new Date(e.target.value))});
                                                    formatDob(new Date(e.target.value))
                                                }}/>
                                                <p className={`${travelData.booking_type_id === 2 ? "text-danger fs-6" : "d-none"}`}>*
                                                    The dob of the oldest member</p>
                                            </div>
                                        </div>
                                        <div className="input-group underwriter-group">
                                            <div>
                                                <label>Departure Date</label>
                                                <input type="date" name="start_date" min={today} onChange={(e) => {
                                                    setAiicoQuoteTravelData({...aiicoQuoteTravelData, [e.target.name]: formatDob(new Date(e.target.value))})
                                                    setTravelDetails({
                                                        ...travelDetails, [e.target.name]: formatDob(new Date(e.target.value))
                                                    })
                                                }}/>
                                            </div>
                                            <div>
                                                <label>Arrival Date</label>
                                                <input type="date" name="end_date" min={aiicoQuoteTravelData.start_date}
                                                       onChange={(e) => {
                                                           setAiicoQuoteTravelData({
                                                               ...aiicoQuoteTravelData,
                                                               [e.target.name]: formatDob(new Date(e.target.value))
                                                           });
                                                           setTravelDetails({
                                                               ...travelDetails, [e.target.name]: formatDob(new Date(e.target.value))
                                                           })
                                                       }} disabled={aiicoQuoteTravelData.start_date === ""}/>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div>
                                            {/*<p className="text-danger"><em>{err}</em></p>*/}
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            page === 'completeform' && travelData.underwriter_id !== 2 ?
                                <>
                                    {travelData.booking_type_id === 1 ?
                                        <div className={`${page === "completeform" ? "" : "d-none"}`}>
                                            <p>Complete Form</p>
                                            <div className="input-group underwriter-group">
                                                <div>
                                                    <label>Title</label>
                                                    <select
                                                        name="title_id"
                                                        onChange={(e) => setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value),
                                                        })}
                                                    >
                                                        <option defaultValue="" disabled selected>
                                                            Select Title
                                                        </option>
                                                        {title.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Surname </label>
                                                    <input type="text" name="lastname"
                                                           value={buydata.lastname === "" ? customerinfo.lastname : buydata.lastname}
                                                           onChange={(e) => handleControlledInput(e, 'lastname')}/>
                                                </div>
                                                <div>
                                                    <label>Firstname</label>
                                                    <input type="text" name="firstname"
                                                           value={buydata.firstname === "" ? customerinfo.firstname : buydata.firstname}
                                                           onChange={(e) => handleControlledInput(e, 'firstname')}/>
                                                </div>
                                            </div>
                                            <div className="input-group underwriter-group">
                                                <div>
                                                    <label>Middle Name</label>
                                                    <input type="text" name="middlename" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                </div>
                                                <div>
                                                    <label>Gender</label>
                                                    <select
                                                        name="gender_id"
                                                        onChange={(e) => setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value),
                                                        })}
                                                    >
                                                        <option defaultValue="" disabled selected>
                                                            Select Gender
                                                        </option>
                                                        {gender.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                {/*<div>*/}
                                                {/*    <label>Date Of Birth</label>*/}
                                                {/*    <input type="date" name="dob" min={dob.current} onChange={(e) => {*/}
                                                {/*        setData({...buydata, [e.target.name]: e.target.value})*/}
                                                {/*    }}/>*/}
                                                {/*</div>*/}
                                                <div>
                                                    <label>Nationality</label>
                                                    <input type="text" name="nationality" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="input-group underwriter-group">
                                                <div>
                                                    <label>State Of Residence</label>
                                                    <select
                                                        name="state_id"
                                                        onChange={(e) => setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value),
                                                        })}
                                                    >
                                                        <option defaultValue="" disabled selected>
                                                            Select State
                                                        </option>
                                                        {states.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Address</label>
                                                    <input type="text" name="address" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                </div>
                                                <div>
                                                    <label>ZipCode</label>
                                                    <input type="text" name="zip_code" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }} disabled={buydata.cover_begins === ""}/>
                                                </div>
                                            </div>
                                            <div className="input-group underwriter-group">
                                                <div>
                                                    <label>Marital Status</label>
                                                    <select
                                                        name="marital_status_id"
                                                        onChange={(e) => setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value),
                                                        })}
                                                    >
                                                        <option defaultValue="" disabled selected>
                                                            Select Status
                                                        </option>
                                                        {maritalstatus.map((data, index) => {
                                                            return (
                                                                <option key={index} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div>
                                                    <label>Occupation</label>
                                                    <input type="text" name="occupation" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                </div>
                                                <div>
                                                    <label>Passport No</label>
                                                    <input type="text" name="passport_no" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                    <p className="fs-6 fst-italic text-danger">{errors.current.passportNo}</p>
                                                </div>
                                            </div>
                                            <div className="input-group justify-content-start px-4">
                                                <div>
                                                    <label>Pre Existing Medical Condition</label>
                                                    <select name="pre_exisiting_medical_condition" onChange={(e) => {
                                                        setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value)
                                                        })
                                                    }}>
                                                        <option value='false'>No</option>
                                                        <option value='true'>Yes</option>
                                                    </select>
                                                </div>
                                                <div
                                                    className={`${buydata.pre_exisiting_medical_condition ? "" : "d-none"}`}>
                                                    <label>Medical Condition</label>
                                                    <input type="text" name="medical_condition" onChange={(e) => {
                                                        setData({
                                                            ...buydata,
                                                            [e.target.name]: JSON.parse(e.target.value),
                                                        })
                                                    }}/>
                                                </div>
                                                {/* <div>
                                                <label>Payment mode</label>
                                                <select onChange={(e) => setMode(e.target.value)}>
                                                    <option>Select State</option>
                                                    <option value='pat'>Pay with Transfer</option>
                                                    <option value='pac'>Pay with Card</option>
                                                </select>
                                            </div> */}
                                            </div>
                                            <hr/>
                                            <div>
                                                <h6>
                                                    Next Of Kin Details
                                                </h6>
                                            </div>
                                            <div className="input-group underwriter-group">
                                                <div>
                                                    <label>FullName </label>
                                                    <input type="text" name="next_of_kin_name" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>
                                                </div>
                                                <div>
                                                    <label>Relationship </label>
                                                    <input type="text" name="next_of_kin_relationship"
                                                           onChange={(e) => {
                                                               setData({...buydata, [e.target.name]: e.target.value})
                                                           }}/>
                                                </div>
                                                <div>
                                                    <label>Telephone</label>
                                                    <input type="tel" name="next_of_kin_phone"
                                                           onInput={(e) => validatePhoneNumber(e.target.value, e.target.name)}/>
                                                    <p className="fs-6 fst-italic text-danger">{errors.current.phone}</p>
                                                </div>
                                            </div>
                                            <div className="input-group">
                                                <div>
                                                    <label>Address</label>
                                                    <input type="text" name="next_of_kin_address" onChange={(e) => {
                                                        setData({...buydata, [e.target.name]: e.target.value})
                                                    }}/>

                                                </div>

                                            </div>

                                            <div>
                                                {/*<p className="text-danger"><em>{err}</em></p>*/}
                                            </div>

                                        </div>
                                        :
                                        Array.from({length: noOfPeople.current + 1}).map((_, index) => (
                                                <div key={index}
                                                     className={`${page === "completeform" && step === index + 1 ? "" : "d-none"}`}>
                                                    <p>Complete Form {index + 1} ({getOrdinalNumber(index + 1)} Person)</p>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>Title</label>
                                                            <select
                                                                name="title_id"
                                                                defaultValue="Select Customer"
                                                                onChange={(e) => setData({
                                                                    ...buydata,
                                                                    [e.target.name]: JSON.parse(e.target.value),
                                                                })}
                                                            >
                                                                <option defaultValue="" disabled selected>
                                                                    Select Provider
                                                                </option>
                                                                {title.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Surname </label>
                                                            <input type="text" name="lastname" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>Firstname</label>
                                                            <input type="text" name="firstname" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                    </div>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>Middle Name</label>
                                                            <input type="text" name="middlename" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>Email</label>
                                                            <input type="email" name="email" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>Phone No</label>
                                                            <input type="tel" name="phone" onInput={(e) => {
                                                                validatePhoneNumber(e.target.value, e.target.name);
                                                            }}/>
                                                            <p className="fs-6 fst-italic text-danger">{errors.current.phone}</p>
                                                        </div>
                                                    </div>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>Gender</label>
                                                            <select
                                                                name="gender_id"
                                                                defaultValue="Select Customer"
                                                                onChange={(e) => setData({
                                                                    ...buydata,
                                                                    [e.target.name]: JSON.parse(e.target.value),
                                                                })}
                                                            >
                                                                <option defaultValue="" disabled selected>
                                                                    Select Gender
                                                                </option>
                                                                {gender.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Date Of Birth</label>
                                                            <input type="date" name="dob" min={dob.current}
                                                                   onChange={(e) => {
                                                                       setData({
                                                                           ...buydata,
                                                                           [e.target.name]: e.target.value
                                                                       })
                                                                   }}/>
                                                            <p className="text-danger fs-6">* No child should surpass the
                                                                age of 18.</p>
                                                        </div>
                                                        <div>
                                                            <label>Nationality</label>
                                                            <input type="text" name="nationality" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                    </div>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>State Of Residence</label>
                                                            <select
                                                                name="state_id"
                                                                defaultValue="Select Customer"
                                                                onChange={(e) => setData({
                                                                    ...buydata,
                                                                    [e.target.name]: JSON.parse(e.target.value),
                                                                })}
                                                            >
                                                                <option defaultValue="" disabled>
                                                                    Select State
                                                                </option>
                                                                {states.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Address</label>
                                                            <input type="text" name="address" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>ZipCode</label>
                                                            <input type="text" name="zip_code" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }} disabled={buydata.cover_begins === ""}/>
                                                        </div>
                                                    </div>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>Marital Status</label>
                                                            <select
                                                                name="marital_status_id"
                                                                defaultValue="Select Customer"
                                                                onChange={(e) => setData({
                                                                    ...buydata,
                                                                    [e.target.name]: JSON.parse(e.target.value),
                                                                })}
                                                            >
                                                                <option defaultValue="" disabled selected>
                                                                    Select Status
                                                                </option>
                                                                {maritalstatus.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Ocupation</label>
                                                            <input type="text" name="occupation" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>Passport No</label>
                                                            <input type="text" name="passport_no" onInput={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                            <p className="fs-6 fst-italic text-danger">{errors.current.passportNo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="input-group">
                                                        <div>
                                                            <label>Pre Existing Medical Condition</label>
                                                            <select name="pre_exisiting_medical_condition"
                                                                    onChange={(e) => {
                                                                        setData({
                                                                            ...buydata,
                                                                            [e.target.name]: JSON.parse(e.target.value)
                                                                        })
                                                                    }}>
                                                                <option value='false'>No</option>
                                                                <option value='true'>Yes</option>
                                                            </select>
                                                        </div>
                                                        <div
                                                            className={`${buydata.pre_exisiting_medical_condition ? "" : "d-none"}`}>
                                                            <label>Medical Condition</label>
                                                            <input type="text" name="medical_condition" onChange={(e) => {
                                                                setData({
                                                                    ...buydata,
                                                                    [e.target.name]: JSON.parse(e.target.value),
                                                                })
                                                            }}/>
                                                        </div>
                                                        {/* <div>
                                                <label>Payment mode</label>
                                                <select onChange={(e) => setMode(e.target.value)}>
                                                    <option>Select State</option>
                                                    <option value='pat'>Pay with Transfer</option>
                                                    <option value='pac'>Pay with Card</option>
                                                </select>
                                            </div> */}
                                                    </div>
                                                    <hr/>
                                                    <div>
                                                        <h6>
                                                            Next Of Kin Details
                                                        </h6>
                                                    </div>
                                                    <div className="input-group underwriter-group">
                                                        <div>
                                                            <label>FullName </label>
                                                            <input type="text" name="next_of_kin_name" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>
                                                        </div>
                                                        <div>
                                                            <label>Relationship </label>
                                                            <input type="text" name="next_of_kin_relationship"
                                                                   onChange={(e) => {
                                                                       setData({
                                                                           ...buydata,
                                                                           [e.target.name]: e.target.value
                                                                       })
                                                                   }}/>
                                                        </div>
                                                        <div>
                                                            <label>Telephone</label>
                                                            <input type="tel" name="next_of_kin_phone"
                                                                   onInput={(e) => validatePhoneNumber(e.target.value, e.target.name)}/>
                                                            <p className="fs-6 fst-italic text-danger">{errors.current.phone}</p>
                                                        </div>
                                                    </div>
                                                    <div className="input-group">
                                                        <div>
                                                            <label>Address</label>
                                                            <input type="text" name="next_of_kin_address" onChange={(e) => {
                                                                setData({...buydata, [e.target.name]: e.target.value})
                                                            }}/>

                                                        </div>

                                                    </div>

                                                    <div>
                                                        {/*<p className="text-danger"><em>{err}</em></p>*/}
                                                    </div>

                                                </div>
                                            )
                                        )}
                                </> :
                                <div className={`${page === "completeform" ? "" : "d-none"}`}>
                                    <p>Complete Form</p>
                                    <div className="input-group underwriter-group">
                                        <div>
                                            <label>Title</label>
                                            <select
                                                name="titleId"
                                                onChange={(e) => {
                                                    setTravelDetails({
                                                        ...travelDetails,
                                                        [e.target.name]: e.target.value, premium_id: quote?.id
                                                    });
                                                }}
                                            >
                                                <option defaultValue="" disabled selected>
                                                    Select Title
                                                </option>
                                                {title.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.id}>
                                                            {data.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            <label>Phone No</label>
                                            <input type="text" name="sms" onChange={(e) => {
                                                validatePhoneNumber(e.target.value, e.target.name);                                            }}/>
                                            <p className="fs-6 fst-italic text-danger">{errors.current.phone}</p>
                                        </div>
                                        <div>
                                            <label>Next of Kin FullName </label>
                                            <input type="text" name="next_of_kin_name" onChange={(e) => {
                                                setTravelDetails({...travelDetails, [e.target.name]: e.target.value})
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="input-group underwriter-group">
                                        <div>
                                            <label>Next of Kin Address</label>
                                            <input type="text" name="next_of_kin_address" onChange={(e) => {
                                                setTravelDetails({...travelDetails, [e.target.name]: e.target.value})
                                            }}/>
                                        </div>
                                        <div>
                                            <label>Next of Kin Relationship </label>
                                            <input type="text" name="next_of_kin_relationship"
                                                   onChange={(e) => {
                                                       setTravelDetails({...travelDetails, [e.target.name]: e.target.value})
                                                   }}/>
                                        </div>
                                        <div>
                                            <label>Passport No</label>
                                            <input type="text" name="passportNumber" onChange={(e) => {
                                                validatePassportNo(e)                                            }}/>
                                            <p className="fs-6 fst-italic text-danger">{errors.current.passportNo}</p>
                                        </div>
                                    </div>
                                    <div className="input-group underwriter-group">
                                        <div>
                                            <label>Pre Existing Medical Condition</label>
                                            <select name="preMedical" onChange={(e) => {
                                                setTravelDetails({
                                                    ...travelDetails,
                                                    [e.target.name]: JSON.stringify(e.target.value)
                                                })
                                            }}>
                                                <option value='false'>No</option>
                                                <option value='true'>Yes</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label>Departure Date</label>
                                            <input type="date" readOnly={true} value={travelDetails.start_date} name="start_date" min={today} onChange={(e) => {
                                                setAiicoQuoteTravelData({
                                                    ...aiicoQuoteTravelData,
                                                    [e.target.name]: formatDob(new Date(e.target.value))
                                                })
                                            }}/>
                                        </div>
                                        <div>
                                            <label>Arrival Date</label>
                                            <input type="date" readOnly={true} value={travelDetails.end_date} name="end_date" min={aiicoQuoteTravelData.start_date}
                                                   onChange={(e) => {
                                                       setAiicoQuoteTravelData({
                                                           ...aiicoQuoteTravelData,
                                                           [e.target.name]: formatDob(new Date(e.target.value))
                                                       })
                                                   }} disabled={aiicoQuoteTravelData.start_date === ""}/>
                                        </div>
                                    </div>
                                    <div className="input-group underwriter-group">
                                        <div
                                            className={`${travelDetails.preMedical === 'true' ? "" : "d-none"}`}>
                                            <label>Medical Condition</label>
                                            <input type="text" name="medical" onChange={(e) => {
                                                setTravelDetails({
                                                    ...travelDetails,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }}/>
                                        </div>
                                        <div className="input-group">
                                            <div>
                                                <label>
                                                    Upload Passport *
                                                </label>
                                                <input
                                                    type="file"
                                                    ref={click1}
                                                    name="passportUrl"
                                                    onChange={encodeImageFileAsURL}
                                                    hidden
                                                />
                                                <span className="fileinput" onClick={() => file1()}>
                                                    Choose File
                                                  </span>
                                                <span className="filename">
                                                    {filename.passportName}
                                                  </span>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Payment mode</label>
                                            <select onChange={(e) => setMode(e.target.value)}>
                                                <option value="">Select mode</option>
                                                <option value="spl">Send Payment Link</option>
                                                <option value="pac"> Pay with card</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div>
                                        {/*<p className="text-danger"><em>{err}</em></p>*/}
                                    </div>
                                </div>
                        }
                        {

                        }
                        {page === "completeform" ? <div className="fs-6 fst-italic text-danger">{error}</div> :
                            <div className="fs-6 fst-italic text-danger">{quoteError}</div>}
                        {/*<div>{quoteError}</div>*/}
                        {/*<button type="button" onClick={handleNext}>Next</button>*/}
                        {/*{step > 1 && <button type="button" onClick={handleBack}>Back</button>}*/}

                        <div className="page-control">
                            {page === "completeform" && step > 1 ?
                                <span onClick={handleBack}>Back</span>
                                :
                                page === "completeform" ? (
                                    <span onClick={() => setPage("startform")}>Back</span>
                                ) : page === "startform" ? (
                                    <span onClick={() => setPage("")}>Back</span>
                                ) : (
                                    <div className={`next-box `}></div>
                                )}
                            {page === "completeform" && travelData.underwriter_id !== 2 ? (
                                <div
                                    className={`next-box ${familyData.current.length === step ? '' : !buyverification(buydata) ? "opacity-50 " : ""}`}>
                                    <div
                                        onClick={() => familyData.current.length > step ? setStep((prevStep) => prevStep + 1) : !buyverification(buydata) ? null : buypolicy()}>{step < noOfPeople.current ? "Next" : "Buy Policy"}</div>
                                </div>
                            ) : page === "completeform" && travelData.underwriter_id === 2 ? (
                                    <div
                                        className={`next-box ${ !buyverification(travelDetails) && mode === '' ? "opacity-50 " : ""}`}>
                                        <div
                                            onClick={() => !buyverification(travelDetails) && mode === ''  ? null : buypolicy()}>{"Buy Policy"}</div>
                                    </div>
                                )
                                : page === "startform" ? (
                                <div
                                    className={`next-box ${!quotedata(travelData.underwriter_id !== 2 ? travelData : aiicoQuoteTravelData) ? "opacity-50 " : ""}`}>
                                    <div onClick={getQUOTE}>Get Quote</div>
                                </div>
                            ) : (
                                <div className={`next-box `}></div>
                            )}
                        </div>
                    </form>
                    <PreviewBuyTravel
                        show={travelmodal}
                        data={quote}
                        config={config}
                        fullconfig={fullConfig}
                        startLoader={() => {
                            setVerifyIsLoading(true);
                            setTravelModal(false)
                        }}
                        stopLoader={() => setVerifyIsLoading(false)}
                        onHide={() => setTravelModal(false)}
                    />
                    <PreviewQuote underwriter={travelData.underwriter_id} show={modal} data={quote}
                                  onHide={() => setModal(false)} cont={next}/>
                    <Emailsent show={emailmodal} onHide={hideemail}/>
                </div>
                {isLoading ? <Loader/> : ""}
                {verifyIsLoading ? <Loader/> : ""}

            </div>
        </div>
);
}

export default Buytravel;
