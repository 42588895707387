import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { usePaystackPayment } from 'react-paystack';
import { useBuyVehiclePolicy } from '../../Hooks/buy-vehiclepolicy';
import Termsmodal from '../modal/Termsmodal';

function PreviewBuyvehicle(props) {
    const initializePayment = usePaystackPayment(props.config);
    const { verifypolicy } = useBuyVehiclePolicy()
    const [check, setCheck] = useState(false)
    const [modal, setModal] = useState(false)

    const onSuccess = (reference) => {
        verifypolicy(props.config.reference)
        // console.log(reference);
    };
    // console.log(check);

    const onClose = () => {
    }

    const checkcomplete = () => {
        if(check) {
            props.onHide();
            initializePayment(onSuccess, onClose)
        }
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Preview</h6>
                    <p>
                        Vehicle Cover
                    </p>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Vehicle Make</label>
                        <p>{props.quote.vehicle_make} </p>
                    </div>
                    <div>
                        <label>Vehicle Model</label>
                        <p>{props.quote.vehicle_model}</p>
                    </div>
                    <div>
                        <label>Vehicle Type</label>
                        <p>{props.quote.vehicle_class}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Flood Damage</label>
                        <p>{props.quote.flood_cover}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div className='total-premium'>
                        <label>Amount</label>
                        <p>₦{props.config.amount / 100}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className='d-flex justify-content-between '>
                    <div className='align-self-center'>
                    <input
                    type="checkbox"
                    id="flood_cover_yes"
                    name="flood_cover"
                    // checked={check === 1 ? true : false}
                    value="1"
                    onChange={() =>
                      setCheck(!check)
                    }
                  />
                  <label htmlFor="html" className='px-2 tandcbutton'>I accept <button className='' onClick={() => setModal(true)}>terms and conditions</button></label>
                    </div>
                    <div className={`next-box ${check ? '' : 'non-active'} `}>
                    <div onClick={checkcomplete}>Continue</div>
                </div>
                </div>
                <Termsmodal show={modal} onHide={() => setModal(false)} />
            </Modal.Body>
        </Modal>
    )
}

export default PreviewBuyvehicle
