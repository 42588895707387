import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';

export const useUnderwriter = () => {
    const [error, setError] = useState(null)
    const [resourcesloding, setIsLoading] = useState(null)
    const [underwriters, setUnderwriters] = useState([])
    const [healthplan, setHealthPlan] = useState([])
    const [state, setState] = useState([])
    const [lga, setLga] = useState([])
    const [residencelga, setResidenceLga] = useState([])
    const [hospital,setHospital] = useState([])
    const [resources, setResources] = useState({
        gender: [],
        idmeans: [],
        payment_frequency: [],
        states: [],
        vehicle_category: [],
        vehicle_class: [],
        vehicle_make: [],
        vehicle_usage: [],
        color: [],
        id_means: [],
        allianz_vehicle_class: [],
        health_hospital: [],
        year: []
    })

    const [gender, setGender] = useState([])

    const getunderwriter = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/underwriter`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                setUnderwriters(response.data.data)
                    setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const chi_resourses =
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/vehicle/resources`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                setResources({
                    ...resources,
                    gender: response.data.data.gender,
                    idmeans: response.data.data.idmeans,
                    payment_frequency: response.data.data.payment_frequency,
                    states: response.data.data.states,
                    vehicle_category: response.data.data.vehicle_category,
                    vehicle_class: response.data.data.vehicle_class,
                    vehicle_make: response.data.data.vehicle_make,
                    vehicle_usage: response.data.data.vehicle_usage,
                    allianz_vehicle_class: response.data.data.allianz_vehicle_class,
                })
                    setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }


    const aiico_resourses =
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/vehicle/resources/aiico`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setResources({
                    ...resources,
                    gender: response.data.data.gender,
                    payment_frequency: response.data.data.payment_frequency,
                    states: response.data.data.states,
                    vehicle_category: response.data.data.vehicle_category,
                    vehicle_class: response.data.data.vehicle_class,
                    vehicle_make: response.data.data.vehicle_make,
                    vehicle_usage: response.data.data.vehicle_usage,
                    color: response.data.data.color,
                    idmeans: response.data.data.id_means,
                    year: response.data.data.year
                })
                    setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }

    const health_resourses =
    async () => {
        setIsLoading(true)
        setError(null)

        const cookies = new Cookies();
        let token =cookies.get('xhrToken')

        await axios.get(`${API}/gender`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setGender(response.data.data)
                setIsLoading(false)

        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }

    const hmo_plan = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/hmo/plans`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                setHealthPlan(response.data.data)
                // console.log(response);
                    setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)

            })
        }, [setHealthPlan]
    )


    const hmo_states = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/hmo/states`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                setState( response.data.data)
                // setResidenceLga(response.data.data)
                // console.log(response);

                    setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )


    const hmo_lga =
    async (stateID) => {
        setIsLoading(true)
        setError(null)

        const data = {
            "state_id": stateID
        }

        const cookies = new Cookies();
        let token =cookies.get('xhrToken')

        await axios.post(`${API}/hmo/lga`, data,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setLga( response.data.data)
            // console.log(response);
            setIsLoading(false)
            return response.data.data
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }

    const hmo_residence_lga =
    async (stateID) => {
        setIsLoading(true)
        setError(null)

        const data = {
            "state_id": stateID
        }

        const cookies = new Cookies();
        let token =cookies.get('xhrToken')

        await axios.post(`${API}/hmo/lga`, data,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            setResidenceLga( response.data.data)
            // console.log(response);

                setIsLoading(false)

        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }


    const downloadfile =
    async () => {
        setIsLoading(true)
        setError(null)

        const cookies = new Cookies();
        let token =cookies.get('xhrToken')

        await axios.get(`${API}/health/policy/template/individual`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // setResidenceLga( response.data.data)
            // console.log(response);
                setIsLoading(false)
            return response
        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            // console.log(err.response.data.message)
        })
    }



    const hmo_hospital =
    async (data) => {
        setIsLoading(true)
        setError(null)

        const cookies = new Cookies();
        let token =cookies.get('xhrToken')

        await axios.post(`${API}/hmo/hospitals`, data,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        } ).then((response) => {
            // console.log(response);
            setHospital(response.data.data.hospitals )

            setIsLoading(false)

        }).catch((err) => {
            setIsLoading(false)
            setError(err.response.data.message)
            setHospital([])
            console.log(err.response.data.message)
        })
    }

    return { downloadfile, underwriters, chi_resourses, gender, hmo_hospital, hospital, hmo_plan, healthplan, state, hmo_states, hmo_lga, lga, hmo_residence_lga, residencelga, health_resourses, resources, aiico_resourses, getunderwriter, resourcesloding, error}
}
